* {
    box-sizing:border-box
}

a {
    text-decoration:none;
}

$breakSingle: 40rem;
$breakWide:   90rem;

:root {
    --color-bg: hsl(0 0% 100%);
    --color-fg: black;
    --color-highlight: red;
    --color-text-fg: white;
    --color-text-bg: hsl(0 0% 100%);
    --color-text-bg-focus: hsl(56 100% 95%);
    --color-gray-link: hsl(0 0% 60%);

    --fontsize-body:1.125rem;
    --fontsize-nav: 1.3rem;
    --fontsize-textarea: 1.5625rem;
    --fontsize-lineheight: 1.35;
    --side-margin: .5rem;
    --font-monospace: 'DejaVu Sans Mono', 'Consolas', 'SF Mono', monospace;
    //font-family: 'SF Mono', SFMono-Regular, ui-monospace;
}

.dark-theme {
    --color-bg: black;
    --color-fg: white;
    --color-highlight: hsl(0, 100%, 67%);
    --color-text-fg: white;
    --color-text-bg: hsl(0 0% 0%);
    --color-text-bg-focus: hsl(0 0% 7%);
    --color-gray-link: hsl(0 0% 70%);
}

@media (min-width: $breakSingle) {
    :root {
        --fontsize-body:1.375rem;
        --fontsize-nav: 2rem;
        --side-margin: 1rem;
        --fontsize-lineheight: 1.4;
        --fontsize-textarea: 2.64rem;
    }
}

body {
    font-size:var(--fontsize-body);
    margin:0;
    background:var(--color-bg);
    color:var(--color-fg);
}

button {
    background:transparent;
    border:0;
    padding:0;
    outline:none;
    color:var(--color-fg);
    cursor:pointer;
}

button .MuiSvgIcon-root {
    font-size:var(--fontsize-nav);
}

button:hover {
    color:var(--color-highlight);
}

.error {
    margin:.5rem 0;
}

.error samp {
    margin:0 .25rem;
}

#nav,
.error,
.text textarea,
.text header {
    padding:0 var(--side-margin);
}

#nav {
    margin:.5rem 0 .5rem;
    font-size:var(--fontsize-nav);
}

#nav a {
    color:var(--color-fg);
    font-family:'Noto Serif', 'Georgia', serif;
}

#nav .active {
    color:var(--color-highlight);
}

.options {
    float:right;
}

.options button {
    margin:0 .5rem;
}

.text {
    margin:.5rem 0 4rem;
}

.info {
    font-family:var(--font-monospace);
}

.name {
    color: var(--color-fg);
}

a.name:hover {
    color:var(--color-highlight);
}

.active.name {
    color:var(--color-highlight);
    //color:black;
}

.mod {
    user-select:none;
    cursor: pointer;
    font-size:1em;
    font-family:var(--font-monospace);
}

.mod:hover,
.mod {
    color:transparent;
    background:var(--color-bg);
}

.mod.unsaved {
    background:orange;
}

.mod.saved {
    background:green;
}

.name {
    margin-right:1.5rem;
}



.text,
.text textarea {
    max-width:66rem;
}

.text textarea {
    color:var(--color-fg);
    background:var(--color-text-bg);

    margin-top:.5rem;
    overflow:hidden;
    border:none;
    outline:none;
    resize:none;
    width:100%;
    
    font-size:var(--fontsize-textarea);
    line-height:var(--fontsize-lineheight);
    font-family:var(--font-monospace);
}

.del {
    padding:0 .5rem;
    position:absolute;
    right:var(--side-margin);
}

.del svg {
    fill:var(--color-highlight);
}

#nav input, 
#nav a {
    margin-right:1rem;
}

#sub a {
    font-family:var(--font-monospace);
    margin-right:1rem;
}

.isOffline {
    width:1rem;
    height:1rem;
}

@media (min-width: $breakSingle) {
    #nav a {
        margin-right:1.375rem;
    }
    #nav {
        margin-bottom:1rem;
    }
    /*
    .del {
        right:20%;
    }
    */
    .text {
        margin-bottom:6rem;
    }
}
.success svg {
    fill:blue;
}

.fail svg {
    fill:red;
}

.status {
    font-size:1rem;
    font-family:var(--font-monospace);
}
